import React from 'react';
import { footerLogo, footerImg2 } from '../assets/images';

const Footer = () => {
  return (
    <div className='footer'>
      <div className="left_sec">
        <img src={footerLogo} alt='Footer lOog' />
        <p>AiVolveX.Ai Ltd 2011-2023 © - All rights reserved.</p>
      </div>
      <div className="right_sec">
        <img src={footerImg2} alt='Footer lOog' />
        <p>1CloudHub Ltd 2011-2023 © - All rights reserved.</p>
      </div>
    </div>
  );
};

export default Footer;
