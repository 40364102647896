import React from "react";
import { logo, ban1, ban2, banCloud, secimg } from "../assets/images";
import { AiOutlineArrowRight } from "../config/icons";

const Banner = (props) => {
  return (
    <>
      <div className="home_banner">
        <div className="container">
          <div className="logo_sec">
            <img src={logo} className="logo-img" alt="logo_img" />
            <button
              className="btn reg_btn"
              onClick={props.scrollToRegistrationForm}
            >
              Register Now <AiOutlineArrowRight />
            </button>
          </div>
          <div className="banner_content_sec">
            <h2>
              AI Horizon : Modernizing Enterprises with <br />{" "}
              <span>AWS and Gen AI</span>
            </h2>
            {/* <p>where we'll explore boundless possibilities</p> */}
          </div>
          <div className="btn_sec">
            <button
              className="btn reg_btn"
              onClick={props.scrollToRegistrationForm}
            >
              Register Now <AiOutlineArrowRight />
            </button>
          </div>
          <div className="bannerimg_sec">
            <img src={banCloud} alt="banner_logo" className="log1" />
            <img src={ban2} alt="banner_logo" className="log2" />
            <img src={ban1} alt="banner_logo" className="log3" />
          </div>
        </div>
      </div>
      <div className="banner_seconsec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="left_text">
                <p>
                  Learn from industry experts about modernizing your business
                  with AWS and Gen AI, discovering best practices and strategies
                  to elevate efficiency and foster innovation within your
                  organization.
                </p>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-lg-6">
              <div className="sec_imgsec">
                <img src={secimg} alt="sec_img" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
