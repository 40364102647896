import { memo } from "react";
import { Col, Row } from "antd";
import dataimg from "../../assets/newimages/icons8-database-administrator-64.png";
import data from "../../assets/newimages/database.svg";
import app from "../../assets/newimages/cloud-computing.svg";
import dev from "../../assets/newimages/devops.svg";
import ai from "../../assets/newimages/artificial-intelligence.svg";
import success from "../../assets/newimages/success.svg";
import qa from "../../assets/newimages/qa.svg";
const FourBox = () => {
  return (
    <>
      <section className="mt-5 container">
        <Row>
          <Col span={24} className="text-center">
            <h1 className="join-us-now text-uppercase">
              Feature topics and learning
            </h1>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mt-4">
          <Col xs={24} sm={24} md={4} lg={4} xl={4} className="px-2 py-1">
            <div className="d-flex justify-content-center">
              <img src={data} className="w100 w-img" />
            </div>
            <div className="d-flex justify-content-center mt-1">
              Database Modernization
            </div>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} className="px-2 py-1">
            <div className="d-flex justify-content-center">
              <img src={app} className="w100 w-img" />
            </div>
            <div className="d-flex justify-content-center mt-1">
              Application Modernization
            </div>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} className="px-2 py-1">
            <div className="d-flex justify-content-center">
              <img src={dev} className="w100 w-img" />
            </div>
            <div className="d-flex justify-content-center mt-1">DevOps</div>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} className="px-2 py-1">
            <div className="d-flex justify-content-center">
              <img src={ai} className="w100 w-img" />
            </div>
            <div className="d-flex justify-content-center mt-1">
              Generative AI
            </div>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} className="px-2 py-1">
            <div className="d-flex justify-content-center">
              <img src={success} className="w100 w-img" />
            </div>
            <div className="d-flex justify-content-center mt-1">
              Customer Success Stories
            </div>
          </Col>
          <Col xs={24} sm={24} md={4} lg={4} xl={4} className="px-2 py-1">
            <div className="d-flex justify-content-center">
              <img src={qa} className="w100 w-img" />
            </div>
            <div className="d-flex justify-content-center mt-1">Live Q&A</div>
          </Col>
        </Row>
        <br></br>
      </section>
    </>
  );
};

export default memo(FourBox);
