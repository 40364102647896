import { Col, Row } from "antd";
import { aivolvexicon } from "../../assets/singapore";

const Agenda = () => {
  return (
    <>
      <section className="mt-5 container">
        <Row className="mt-3">
          <Col span={24} className="text-center">
            <div className="eight">
              <h1 className="join-us-now">AGENDA</h1>
            </div>
          </Col>
        </Row>
        <Row className="mt-4" gutter={[25, 25]}>
          <Col span={24} className="relative">
            <div className="green-bg agenda-time">
              <label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  className="mr-1"
                >
                  <g clip-path="url(#clip0_2927_5568)">
                    <path
                      d="M14.3883 12.7821L11.5791 10.6753V6.38349C11.5791 5.95196 11.2303 5.60315 10.7988 5.60315C10.3672 5.60315 10.0184 5.95196 10.0184 6.38349V11.0655C10.0184 11.3113 10.1339 11.543 10.3306 11.6897L13.4519 14.0307C13.5923 14.1361 13.7562 14.1868 13.9193 14.1868C14.1573 14.1868 14.3914 14.0799 14.5443 13.8739C14.8035 13.5297 14.7332 13.0404 14.3883 12.7821Z"
                      fill="white"
                    />
                    <path
                      d="M10.7973 0.928589C5.24213 0.928589 0.723267 5.44745 0.723267 11.0026C0.723267 16.5577 5.24213 21.0766 10.7973 21.0766C16.3524 21.0766 20.8713 16.5577 20.8713 11.0026C20.8713 5.44745 16.3524 0.928589 10.7973 0.928589ZM10.7973 19.5159C6.10361 19.5159 2.28391 15.6962 2.28391 11.0026C2.28391 6.30893 6.10361 2.48923 10.7973 2.48923C15.4917 2.48923 19.3106 6.30893 19.3106 11.0026C19.3106 15.6962 15.4909 19.5159 10.7973 19.5159Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2927_5568">
                      <rect
                        width="20.148"
                        height="20.148"
                        fill="white"
                        transform="translate(0.723267 0.928589)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                10.00 - 10.15
              </label>
            </div>
            <div className="border-box border-green">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="110"
                        height="110"
                        viewBox="0 0 110 110"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_2927_5579)">
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M66.5188 14.8461L72.9518 7.10252C73.4905 6.455 73.1249 5.43593 72.3937 5.01923L65.9801 1.31385C65.2553 0.897152 64.1842 1.08943 63.8957 1.8779L60.4003 11.3138C60.1052 12.1023 60.2336 12.9804 60.9584 13.3971L64.4346 15.4099C65.1656 15.8268 65.9865 15.4934 66.5188 14.8461Z"
                            fill="#80D261"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M102.451 16.8717L100.802 12.2435C100.603 11.6795 100.526 11.1987 100.757 10.6924L102.906 6.01922C103.361 5.10895 103.259 4.89752 102.232 4.91668L96.9795 5.01931C96.3831 5.0257 96.306 4.88474 95.9149 4.50658L92.3104 1.01264C91.5792 0.307423 91.2713 0.390891 90.9892 1.36525L89.6167 6.19212C89.4627 6.71784 89.4691 6.87796 88.9496 7.16648L84.3446 9.70501C83.453 10.1986 83.4658 10.4358 84.3188 10.9998L88.5199 13.9678C88.9687 14.2883 89.1421 14.7499 89.2511 15.3397L90.1426 20.1666C90.2966 21.1795 90.5338 21.2436 91.3484 20.6282L95.2031 17.6602C95.6008 17.3525 96.3126 17.1603 96.8128 17.2307L101.629 17.8719C102.649 17.9998 102.816 17.8267 102.451 16.8717Z"
                            fill="#FECE85"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M44.6063 46.4484C54.7592 33.3459 52.1295 20.4228 50.2377 12.7176C48.1212 4.1023 36.1403 6.14705 38.5903 15.2625C40.957 24.0703 41.81 30.769 39.4113 43.1599L44.6063 46.4484Z"
                            fill="#FE76A8"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M57.0032 57.5641L84.1011 35.064C84.9799 34.1858 84.9799 32.7499 84.1011 31.8717L77.0459 24.8268C76.1672 23.9487 74.7306 23.9487 73.852 24.8268L51.34 51.9038L57.0032 57.5641Z"
                            fill="#FECE85"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M93.0794 72.1917C102.065 75.0956 104.714 63.243 96.2093 60.6917C88.6667 58.4289 76.0318 55.1854 62.5438 64.3841L65.9494 69.8585C78.0009 68.2366 84.5621 69.4354 93.0794 72.1917Z"
                            fill="#FE76A8"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M44.6083 46.448C54.7612 33.3455 52.1315 20.4224 50.2396 12.7172C49.0466 7.84541 44.6854 6.37748 41.5747 7.67868C43.1268 8.51847 44.4416 10.076 45.0188 12.4287C46.8083 19.7236 49.2646 31.7044 40.8821 44.089L44.6083 46.448Z"
                            fill="#FE5694"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M56.9991 57.5638L84.097 35.0637C84.9757 34.1856 84.9757 32.7496 84.097 31.8715L82.8398 30.615L54.004 54.5639L56.9991 57.5638Z"
                            fill="#FDB440"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M93.0771 72.1916C101.819 75.0184 104.564 63.8774 96.8804 60.9224C96.7905 64.1146 94.1739 67.0634 89.261 66.1532C80.9744 64.6148 74.58 64.2236 64.0552 66.8198L65.9473 69.8583C77.9986 68.2364 84.5597 69.4352 93.0771 72.1916Z"
                            fill="#FE5694"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.6016 44.8521L13.3766 31.711C13.274 30.6212 11.9913 30.0123 10.9267 30.3008L1.54976 32.8137C0.485122 33.0959 -0.329541 34.2625 0.1323 35.2624L5.64799 47.2496C6.10983 48.2495 7.0333 48.9484 8.09794 48.6662L13.1839 47.301C14.2488 47.0188 14.7043 45.9482 14.6016 44.8521Z"
                            fill="#80D261"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M23.8011 15.3144L28.3611 12.0901C29.2846 11.4747 29.2976 11.2183 28.329 10.6862L23.3328 7.92988C22.7684 7.61581 22.7748 7.4427 22.608 6.87226L21.1137 1.63508C20.8122 0.571078 20.4787 0.481223 19.6835 1.25053L15.7711 5.03874C15.3414 5.45544 15.2643 5.60917 14.6166 5.5964L8.90844 5.48738C7.79887 5.46183 7.68347 5.69243 8.18364 6.68616L10.5182 11.7566C10.7684 12.3015 10.6849 12.8272 10.4669 13.4426L8.67742 18.4617C8.27967 19.4938 8.46577 19.6861 9.56895 19.5451L14.7961 18.8465C15.3412 18.776 16.111 18.9811 16.547 19.3145L20.7223 22.5389C21.6075 23.2056 21.8641 23.1351 22.0372 22.0389L22.9992 16.8017C23.1212 16.1606 23.3071 15.6607 23.8011 15.3144Z"
                            fill="#FECE85"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M106.778 44.5445L108.997 31.538C109.183 30.4546 108.099 29.538 106.996 29.538H97.2858C96.189 29.538 95.0989 30.4546 95.2847 31.538L97.5103 44.5445C97.6964 45.6279 98.4082 46.5445 99.5114 46.5445H104.777C105.874 46.5445 106.592 45.6279 106.778 44.5445Z"
                            fill="#80D261"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M104.848 91.7178L108.427 87.4165C109.164 86.5831 109.106 86.3331 108.029 86.064L102.481 84.6921C101.852 84.5381 101.814 84.3716 101.506 83.8587L98.7035 79.1791C98.139 78.2303 97.7928 78.2303 97.2219 79.1791L94.4191 83.8587C94.1112 84.3714 94.0727 84.5381 93.4443 84.6921L87.8965 86.064C86.8191 86.3331 86.7678 86.5833 87.5053 87.4165L91.0779 91.7178C91.4626 92.1795 91.5139 92.7114 91.4626 93.3589L91.0393 98.6793C90.9175 99.7819 91.1483 99.9164 92.181 99.4997L97.0554 97.4677C97.5685 97.2563 98.3638 97.2563 98.8704 97.4677L103.751 99.4997C104.777 99.9164 105.008 99.7819 104.893 98.6793L104.463 93.3589C104.412 92.7114 104.463 92.1793 104.848 91.7178Z"
                            fill="#FECE85"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M80.4558 98.9685L70.5338 91.9429C69.7064 91.3531 68.6417 91.2249 67.9235 91.9429L64.4986 95.3659C63.7801 96.0839 63.9085 97.1479 64.4986 97.9749L71.528 107.892C72.1116 108.719 73.4136 108.61 74.1319 107.892L80.4558 101.571C81.1742 100.853 81.2833 99.5517 80.4558 98.9685Z"
                            fill="#80D261"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M65.9636 62.2953C64.2446 59.8594 62.2629 57.3849 60.127 54.9748C59.1969 53.9236 58.2349 52.8851 57.2538 51.8658C57.241 51.8467 57.2216 51.8273 57.2024 51.8145C56.1314 50.7761 55.041 49.7696 53.9314 48.7888C51.6097 46.7438 49.2366 44.8464 46.8893 43.1799C46.8829 43.1799 46.8829 43.1799 46.8829 43.1799C45.5617 42.2376 44.2532 41.3785 42.9705 40.6094C42.0021 40.026 41.0529 39.5005 40.1228 39.026C33.5167 35.6799 27.9753 35.2568 25.9165 40.2118C25.8716 40.3145 25.5123 41.2888 25.2366 41.8914L23.1456 47.7631L19.6311 57.6349L14.5451 71.9235L10.5492 83.1671L6.09159 95.6799C4.9948 98.7697 5.32846 101.071 6.74571 102.244C7.91937 103.661 10.222 103.994 13.3133 102.898L25.8328 98.4428L37.0824 94.4491L51.3786 89.3659L61.2557 85.853L67.1306 83.7633C67.7334 83.4876 68.7084 83.1288 68.8111 83.0839C73.6405 81.0838 73.3648 75.7634 70.2413 69.3787C69.6898 68.2441 69.0419 67.0774 68.3237 65.8852C67.6119 64.7058 66.823 63.507 65.9698 62.3019C65.9636 62.3017 65.9636 62.2953 65.9636 62.2953Z"
                            fill="#80D261"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M60.1241 54.9738C59.194 53.9226 58.232 52.8842 57.2508 51.8649C57.2381 51.8457 57.2187 51.8263 57.1995 51.8136C56.1285 50.7751 55.0381 49.7686 53.9285 48.7879C53.8644 48.7365 53.8067 48.6788 53.7424 48.6275C51.5488 46.7044 49.3041 44.9096 47.085 43.3199C47.0209 43.2749 46.9502 43.2236 46.8861 43.1789C46.8797 43.1789 46.8797 43.1789 46.8797 43.1789C45.5585 42.2367 44.2501 41.3775 42.9674 40.602C42.0182 40.038 41.0689 39.5059 40.1197 39.0251C37.4004 37.6468 34.854 36.7687 32.667 36.5507C35.0722 37.9673 37.4709 39.6468 39.7349 41.4161C40.3763 41.916 41.0112 42.4226 41.627 42.9353C42.4481 43.6084 43.2367 44.2878 44.0001 44.9609C44.3465 45.2622 44.6864 45.5699 45.0328 45.8776V45.8839C45.7895 46.5698 46.5401 47.2686 47.2711 47.98L47.3288 48.0313C50.4522 51.2685 53.5308 54.9159 56.0386 58.4671C57.5843 60.6339 59.1878 63.2427 60.3102 65.544C63.4336 71.9351 63.7094 77.2491 58.8799 79.2555C58.8608 79.2619 58.8031 79.2811 58.726 79.3132C59.951 81.6529 60.8297 83.8646 61.253 85.8516L67.1279 83.7619C67.7307 83.4862 68.7057 83.1274 68.8084 83.0825C73.6378 81.0825 73.362 75.762 70.2386 69.3774C70.2194 69.3324 70.2001 69.2941 70.1745 69.2492C69.6422 68.1658 69.0072 67.0183 68.321 65.8839C68.2633 65.7876 68.1992 65.6916 68.1415 65.589C68.1415 65.589 68.1351 65.5826 68.1351 65.5762C67.436 64.4351 66.6921 63.3198 65.9673 62.3005C65.9609 62.3005 65.9609 62.2941 65.9609 62.2941C64.3767 60.0441 62.5681 57.7685 60.6247 55.5377C60.4577 55.3458 60.291 55.1599 60.1241 54.9738Z"
                            fill="#81BD41"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M61.2522 85.852C59.8797 79.3777 53.6456 70.4866 46.212 62.7942C38.5155 55.3647 29.6198 49.134 23.1419 47.7621L19.6273 57.6339C25.3483 60.2493 32.0187 65.2814 37.9768 71.0249C43.7236 76.9801 48.7582 83.6467 51.3751 89.3646L61.2522 85.852Z"
                            fill="#FECE85"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M37.0823 94.4487L51.3785 89.3655C50.487 87.4104 49.3069 85.3462 47.915 83.2372L32.8171 88.6026C34.3822 90.5577 35.8252 92.5192 37.0823 94.4487Z"
                            fill="#81BD41"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M61.2559 85.8525C60.8326 83.8652 59.9538 81.6537 58.7288 79.3141C58.4018 79.4359 57.6834 79.7116 57.2024 79.9358L47.9153 83.2371C49.307 85.3461 50.4872 87.4103 51.3788 89.3654L61.2559 85.8525Z"
                            fill="#FDB440"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M37.0837 94.4488C34.3835 90.3141 30.8496 86 26.9949 82.0065C22.9991 78.154 18.6828 74.622 14.5459 71.9231L10.55 83.1668C13.3273 85.2886 16.1107 87.6923 18.7531 90.2437C21.3057 92.8846 23.7109 95.6667 25.8338 98.4424L37.0837 94.4488Z"
                            fill="#FECE85"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M25.8318 98.4419C24.3757 96.5381 22.7852 94.6277 21.105 92.7625L5.46836 98.3201C5.30164 100.089 5.76348 101.435 6.74465 102.243C7.91831 103.66 10.2209 103.993 13.3122 102.897L25.8318 98.4419Z"
                            fill="#81BD41"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M37.0814 94.4492C35.8242 92.5196 34.3812 90.5581 32.8162 88.603L21.1047 92.7632C22.7852 94.6287 24.3757 96.5389 25.8315 98.4427L37.0814 94.4492Z"
                            fill="#FDB440"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M23.797 15.3144L28.3571 12.0901C29.2806 11.4747 29.2935 11.2183 28.3249 10.6862L23.3288 7.92988C22.7643 7.61581 22.7707 7.4427 22.604 6.87226L21.1096 1.63508C20.8081 0.571078 20.4747 0.481223 19.6794 1.25053L17.9541 2.92351C18.6531 5.19269 19.0381 7.69268 19.0381 10.3145C19.0381 13.7824 18.3646 17.0323 17.1909 19.8145L20.7185 22.5389C21.6036 23.2056 21.8602 23.1351 22.0333 22.0389L22.9953 16.8017C23.1171 16.1606 23.303 15.6607 23.797 15.3144Z"
                            fill="#FDB440"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M14.5994 44.8521L13.3745 31.711C13.2718 30.6212 11.9892 30.0123 10.9245 30.3008L8.76947 30.8776C9.00667 32.2687 9.12868 33.7238 9.12868 35.2173C9.12868 39.9481 7.87793 44.2686 5.81274 47.5635C6.30652 48.3905 7.14674 48.916 8.09597 48.666L13.182 47.3007C14.2466 47.0188 14.7021 45.9482 14.5994 44.8521Z"
                            fill="#81BD41"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M72.9538 7.10171C73.4925 6.45419 73.1269 5.43512 72.3958 5.01842L65.9821 1.31325C65.5974 1.09522 65.1292 1.04411 64.7186 1.15931C65.5075 3.54389 65.9436 6.19783 65.9436 8.99911C65.9436 11.3196 65.6421 13.5441 65.0906 15.5952C65.6229 15.6016 66.1425 15.3067 66.5209 14.8453L72.9538 7.10171Z"
                            fill="#81BD41"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M100.802 12.2435C100.603 11.6794 100.526 11.1986 100.757 10.6923L102.905 6.01913C103.361 5.10887 103.258 4.89743 102.232 4.91659L96.9792 5.01922C96.3827 5.02561 96.3057 4.88465 95.9145 4.50649L93.9392 2.59631C94.2343 4.13472 94.388 5.75021 94.388 7.4168C94.388 12.6861 92.8296 17.4489 90.3281 20.8464C90.5012 21.1541 90.79 21.0514 91.3478 20.6284L95.2025 17.6603C95.6002 17.3527 96.3121 17.1604 96.8122 17.2309L101.629 17.872C102.649 18.0002 102.815 17.8271 102.45 16.8721L100.802 12.2435Z"
                            fill="#FDB440"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M106.78 44.5445L108.999 31.538C109.185 30.4546 108.101 29.538 106.998 29.538H102.771C102.848 30.3456 102.887 31.1726 102.887 32.0124C102.887 37.6341 101.116 42.6727 98.3202 46.1021C98.6409 46.3778 99.0386 46.5443 99.5132 46.5443H104.779C105.875 46.5445 106.594 45.6279 106.78 44.5445Z"
                            fill="#81BD41"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M74.133 107.891L80.4569 101.57C81.1754 100.852 81.2844 99.5509 80.4569 98.9675L70.9327 92.2239C71.4907 94.2816 71.792 96.5059 71.792 98.833C71.792 101.596 71.3623 104.217 70.599 106.577L71.5291 107.891C72.1125 108.718 73.4145 108.609 74.133 107.891Z"
                            fill="#81BD41"
                          />
                          <path
                            fill-rule="evenodd"
                            clip-rule="evenodd"
                            d="M104.848 91.7178L108.427 87.4165C109.165 86.5831 109.107 86.3331 108.03 86.064L102.482 84.6921C101.853 84.5381 101.815 84.3716 101.507 83.8587L98.7042 79.1791C98.1397 78.2303 97.7935 78.2303 97.2226 79.1791L96.3247 80.6792C97.2099 83.1792 97.7037 85.9933 97.7037 88.974C97.7037 92.192 97.1264 95.2241 96.1067 97.8651L97.0559 97.4675C97.5691 97.2561 98.3644 97.2561 98.8709 97.4675L103.752 99.4995C104.778 99.9162 105.009 99.7816 104.893 98.6791L104.464 93.3587C104.412 92.7114 104.464 92.1793 104.848 91.7178Z"
                            fill="#FDB440"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2927_5579">
                            <rect
                              width="109.019"
                              height="109.019"
                              fill="white"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={18}
                      lg={18}
                      xl={18}
                      className="display-flex align-items-center"
                    >
                      <h2 className="border-box-left-title ">
                        Event Opening - Introduction to GenAI
                      </h2>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className="border-box-content"
                >
                  <label>
                    <span className="content-bold">What is GenAI?</span>
                    <br></br> How is the financial services industry using it
                    today?
                  </label>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24} className="relative mt-5">
            <div className="lite-blue-bg agenda-time">
              <label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  className="mr-1"
                >
                  <g clip-path="url(#clip0_2927_5568)">
                    <path
                      d="M14.3883 12.7821L11.5791 10.6753V6.38349C11.5791 5.95196 11.2303 5.60315 10.7988 5.60315C10.3672 5.60315 10.0184 5.95196 10.0184 6.38349V11.0655C10.0184 11.3113 10.1339 11.543 10.3306 11.6897L13.4519 14.0307C13.5923 14.1361 13.7562 14.1868 13.9193 14.1868C14.1573 14.1868 14.3914 14.0799 14.5443 13.8739C14.8035 13.5297 14.7332 13.0404 14.3883 12.7821Z"
                      fill="white"
                    />
                    <path
                      d="M10.7973 0.928589C5.24213 0.928589 0.723267 5.44745 0.723267 11.0026C0.723267 16.5577 5.24213 21.0766 10.7973 21.0766C16.3524 21.0766 20.8713 16.5577 20.8713 11.0026C20.8713 5.44745 16.3524 0.928589 10.7973 0.928589ZM10.7973 19.5159C6.10361 19.5159 2.28391 15.6962 2.28391 11.0026C2.28391 6.30893 6.10361 2.48923 10.7973 2.48923C15.4917 2.48923 19.3106 6.30893 19.3106 11.0026C19.3106 15.6962 15.4909 19.5159 10.7973 19.5159Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2927_5568">
                      <rect
                        width="20.148"
                        height="20.148"
                        fill="white"
                        transform="translate(0.723267 0.928589)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                10:15 – 10:45
              </label>
            </div>
            <div className="border-box border-lite-blue">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="89"
                        height="89"
                        viewBox="0 0 89 89"
                        fill="none"
                      >
                        <path
                          d="M67.213 13.9242H72.3701V2.57856C72.3701 1.15451 71.2156 0 69.7916 0C68.3675 0 67.213 1.15451 67.213 2.57856V13.9242Z"
                          fill="#39326C"
                        />
                        <path
                          d="M56.9005 13.9242H62.0576V2.57856C62.0576 1.15451 60.9031 0 59.4791 0C58.055 0 56.9005 1.15451 56.9005 2.57856V13.9242Z"
                          fill="#39326C"
                        />
                        <path
                          d="M46.5841 13.9242H51.7412V2.57856C51.7412 1.15451 50.5867 0 49.1627 0C47.7386 0 46.5841 1.15451 46.5841 2.57856V13.9242Z"
                          fill="#39326C"
                        />
                        <path
                          d="M36.2719 13.9242H41.429V2.57856C41.429 1.15451 40.2745 0 38.8504 0C37.4264 0 36.2719 1.15451 36.2719 2.57856V13.9242Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M25.9592 13.9242H31.1164V2.57856C31.1164 1.15451 29.9618 0 28.5378 0C27.1137 0 25.9592 1.15451 25.9592 2.57856V13.9242Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M15.6428 13.9242H20.7999V2.57856C20.7999 1.15451 19.6454 0 18.2214 0C16.7973 0 15.6428 1.15451 15.6428 2.57856V13.9242Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M69.7916 88.0151C71.2156 88.0151 72.3701 86.8606 72.3701 85.4365V74.0908H67.213V85.4365C67.213 86.8606 68.3675 88.0151 69.7916 88.0151Z"
                          fill="#39326C"
                        />
                        <path
                          d="M59.4791 88.0151C60.9031 88.0151 62.0576 86.8606 62.0576 85.4365V74.0908H56.9005V85.4365C56.9005 86.8606 58.055 88.0151 59.4791 88.0151Z"
                          fill="#39326C"
                        />
                        <path
                          d="M49.1627 88.0151C50.5867 88.0151 51.7412 86.8606 51.7412 85.4365V74.0908H46.5841V85.4365C46.5841 86.8606 47.7386 88.0151 49.1627 88.0151Z"
                          fill="#39326C"
                        />
                        <path
                          d="M38.8504 88.0151C40.2745 88.0151 41.429 86.8606 41.429 85.4365V74.0908H36.2719V85.4365C36.2719 86.8606 37.4264 88.0151 38.8504 88.0151Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M28.5378 88.0151C29.9618 88.0151 31.1164 86.8606 31.1164 85.4365V74.0908H25.9592V85.4365C25.9592 86.8606 27.1137 88.0151 28.5378 88.0151Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M18.2214 88.0151C19.6454 88.0151 20.7999 86.8606 20.7999 85.4365V74.0908H15.6428V85.4365C15.6428 86.8606 16.7973 88.0151 18.2214 88.0151Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M2.57856 20.8004H13.9242V15.6433H2.57856C1.15451 15.6433 0 16.7978 0 18.2219C0 19.6459 1.15451 20.8004 2.57856 20.8004Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M2.57856 31.1146H13.9242V25.9575H2.57856C1.15451 25.9575 0 27.112 0 28.5361C0 29.9601 1.15451 31.1146 2.57856 31.1146Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M2.57856 41.429H13.9242V36.2719H2.57856C1.15451 36.2719 0 37.4264 0 38.8504C0 40.2745 1.15451 41.429 2.57856 41.429Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M2.57856 51.7432H13.9242V46.5861H2.57856C1.15451 46.5861 0 47.7406 0 49.1646C0 50.5887 1.15451 51.7432 2.57856 51.7432Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M2.57856 62.0574H13.9242V56.9003H2.57856C1.15451 56.9003 0 58.0548 0 59.4788C0 60.9029 1.15451 62.0574 2.57856 62.0574Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M2.57856 72.3717H13.9242V67.2146H2.57856C1.15451 67.2146 0 68.3691 0 69.7932C0 71.2172 1.15451 72.3717 2.57856 72.3717Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M74.0892 15.6433V20.8004H85.4349C86.859 20.8004 88.0135 19.6459 88.0135 18.2219C88.0135 16.7978 86.859 15.6433 85.4349 15.6433H74.0892Z"
                          fill="#39326C"
                        />
                        <path
                          d="M74.0892 31.1146H85.4349C86.859 31.1146 88.0135 29.9601 88.0135 28.5361C88.0135 27.112 86.859 25.9575 85.4349 25.9575H74.0892V31.1146Z"
                          fill="#39326C"
                        />
                        <path
                          d="M74.0892 41.429H85.4349C86.859 41.429 88.0135 40.2745 88.0135 38.8504C88.0135 37.4264 86.859 36.2719 85.4349 36.2719H74.0892V41.429Z"
                          fill="#39326C"
                        />
                        <path
                          d="M74.0892 51.7432H85.4349C86.859 51.7432 88.0135 50.5887 88.0135 49.1646C88.0135 47.7406 86.859 46.5861 85.4349 46.5861H74.0892V51.7432Z"
                          fill="#39326C"
                        />
                        <path
                          d="M74.0892 62.0574H85.4349C86.859 62.0574 88.0135 60.9029 88.0135 59.4788C88.0135 58.0548 86.859 56.9003 85.4349 56.9003H74.0892V62.0574Z"
                          fill="#39326C"
                        />
                        <path
                          d="M74.0892 72.3717H85.4349C86.859 72.3717 88.0135 71.2172 88.0135 69.7932C88.0135 68.3691 86.859 67.2146 85.4349 67.2146H74.0892V72.3717Z"
                          fill="#39326C"
                        />
                        <path
                          d="M76.6688 8.76721H11.3452C9.92111 8.76721 8.7666 9.92172 8.7666 11.3458V76.6694C8.7666 78.0934 9.92111 79.2479 11.3452 79.2479H76.6688C78.0928 79.2479 79.2473 78.0934 79.2473 76.6694V11.3458C79.2473 9.92172 78.0928 8.76721 76.6688 8.76721Z"
                          fill="#AED0FF"
                        />
                        <path
                          d="M79.2454 76.6694V11.3458C79.2454 9.92172 78.0909 8.76721 76.6668 8.76721H44.005V79.2479H76.6668C78.0909 79.2479 79.2454 78.0934 79.2454 76.6694Z"
                          fill="#7C84E8"
                        />
                        <path
                          d="M66.3526 19.0814H21.6575C20.2335 19.0814 19.079 20.2359 19.079 21.66V66.3551C19.079 67.7791 20.2335 68.9336 21.6575 68.9336H66.3526C67.7767 68.9336 68.9312 67.7791 68.9312 66.3551V21.66C68.9312 20.2359 67.7767 19.0814 66.3526 19.0814Z"
                          fill="#5F55AF"
                        />
                        <path
                          d="M68.9311 66.3551V21.66C68.9311 20.2359 67.7766 19.0814 66.3526 19.0814H44.005V68.9336H66.3526C67.7766 68.9336 68.9311 67.7791 68.9311 66.3551Z"
                          fill="#39326C"
                        />
                        <path
                          d="M42.5658 33.0726C42.5568 33.0488 42.5475 33.0255 42.5379 33.0019C42.0662 31.8551 40.9607 31.1147 39.7209 31.1147C39.7199 31.1147 39.7189 31.1147 39.7178 31.1147C38.4768 31.1159 37.3713 31.8589 36.9015 33.0074C36.8936 33.0268 36.8859 33.0464 36.8785 33.066L29.1325 53.404C28.6255 54.7349 29.2936 56.2246 30.6244 56.7314C31.9553 57.2385 33.445 56.5703 33.9518 55.2394L35.2485 51.835H44.1344L45.4165 55.2322C45.8062 56.2648 46.7872 56.9011 47.8293 56.9011C48.1318 56.9011 48.4396 56.8474 48.7394 56.7343C50.0716 56.2315 50.7443 54.7438 50.2413 53.4114L42.5658 33.0726ZM37.2127 46.6781L39.7118 40.1163L42.1883 46.6781H37.2127Z"
                          fill="#F9F9F9"
                        />
                        <path
                          d="M45.4162 55.2322C45.8059 56.2649 46.7869 56.9011 47.829 56.9011C48.1316 56.9011 48.4393 56.8474 48.7391 56.7343C50.0713 56.2315 50.744 54.7439 50.241 53.4114L44.005 36.887V51.8351H44.1341L45.4162 55.2322Z"
                          fill="#E2DFF4"
                        />
                        <path
                          d="M56.4675 31.1147C55.0434 31.1147 53.8889 32.2693 53.8889 33.6933V54.3218C53.8889 55.7459 55.0434 56.9004 56.4675 56.9004C57.8915 56.9004 59.046 55.7459 59.046 54.3218V33.6933C59.046 32.2693 57.8915 31.1147 56.4675 31.1147Z"
                          fill="#E2DFF4"
                        />
                      </svg>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={18}
                      lg={18}
                      xl={18}
                      className="display-flex align-items-center"
                    >
                      <h2 className="border-box-left-title ">GenAI on AWS</h2>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className="border-box-content"
                >
                  <label>
                    <span className="content-bold">
                      {" "}
                      What is AWS doing for GenAI?
                    </span>
                    <br />
                    1. How AWS is leading the GenAI space
                    <br />
                    2. GenAI Technology
                    <br />
                    3. Security
                    <br />
                  </label>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24} className="relative mt-5">
            <div className="pink-bg agenda-time">
              <label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  className="mr-1"
                >
                  <g clip-path="url(#clip0_2927_5568)">
                    <path
                      d="M14.3883 12.7821L11.5791 10.6753V6.38349C11.5791 5.95196 11.2303 5.60315 10.7988 5.60315C10.3672 5.60315 10.0184 5.95196 10.0184 6.38349V11.0655C10.0184 11.3113 10.1339 11.543 10.3306 11.6897L13.4519 14.0307C13.5923 14.1361 13.7562 14.1868 13.9193 14.1868C14.1573 14.1868 14.3914 14.0799 14.5443 13.8739C14.8035 13.5297 14.7332 13.0404 14.3883 12.7821Z"
                      fill="white"
                    />
                    <path
                      d="M10.7973 0.928589C5.24213 0.928589 0.723267 5.44745 0.723267 11.0026C0.723267 16.5577 5.24213 21.0766 10.7973 21.0766C16.3524 21.0766 20.8713 16.5577 20.8713 11.0026C20.8713 5.44745 16.3524 0.928589 10.7973 0.928589ZM10.7973 19.5159C6.10361 19.5159 2.28391 15.6962 2.28391 11.0026C2.28391 6.30893 6.10361 2.48923 10.7973 2.48923C15.4917 2.48923 19.3106 6.30893 19.3106 11.0026C19.3106 15.6962 15.4909 19.5159 10.7973 19.5159Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2927_5568">
                      <rect
                        width="20.148"
                        height="20.148"
                        fill="white"
                        transform="translate(0.723267 0.928589)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                10:45 – 11:15
              </label>
            </div>
            <div className="border-box border-pink">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <img src={aivolvexicon} alt="img" />
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={18}
                      lg={18}
                      xl={18}
                      className="display-flex align-items-center"
                    >
                      <h2 className="border-box-left-title ">
                        AIVolveX Demo and Showcase
                      </h2>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className="border-box-content"
                >
                  <label>
                    <span className="content-bold">Intro on AIVolveX</span> –
                    Fully customizable GenAI SaaS platform on AWS, performing
                    document search & summary,document comparison and GenAI
                    Chatbot
                  </label>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24} className="relative mt-5">
            <div className="green-bg agenda-time">
              <label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  className="mr-1"
                >
                  <g clip-path="url(#clip0_2927_5568)">
                    <path
                      d="M14.3883 12.7821L11.5791 10.6753V6.38349C11.5791 5.95196 11.2303 5.60315 10.7988 5.60315C10.3672 5.60315 10.0184 5.95196 10.0184 6.38349V11.0655C10.0184 11.3113 10.1339 11.543 10.3306 11.6897L13.4519 14.0307C13.5923 14.1361 13.7562 14.1868 13.9193 14.1868C14.1573 14.1868 14.3914 14.0799 14.5443 13.8739C14.8035 13.5297 14.7332 13.0404 14.3883 12.7821Z"
                      fill="white"
                    />
                    <path
                      d="M10.7973 0.928589C5.24213 0.928589 0.723267 5.44745 0.723267 11.0026C0.723267 16.5577 5.24213 21.0766 10.7973 21.0766C16.3524 21.0766 20.8713 16.5577 20.8713 11.0026C20.8713 5.44745 16.3524 0.928589 10.7973 0.928589ZM10.7973 19.5159C6.10361 19.5159 2.28391 15.6962 2.28391 11.0026C2.28391 6.30893 6.10361 2.48923 10.7973 2.48923C15.4917 2.48923 19.3106 6.30893 19.3106 11.0026C19.3106 15.6962 15.4909 19.5159 10.7973 19.5159Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2927_5568">
                      <rect
                        width="20.148"
                        height="20.148"
                        fill="white"
                        transform="translate(0.723267 0.928589)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                11:15 – 11:45
              </label>
            </div>
            <div className="border-box border-green">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="104"
                        height="105"
                        viewBox="0 0 104 105"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_2927_5727)">
                          <path
                            d="M73.0705 3.75415C56.0589 3.75415 42.2189 17.5942 42.2189 34.6058C42.2189 51.6174 56.0589 65.4574 73.0705 65.4574C90.0821 65.4574 103.922 51.6174 103.922 34.6058C103.922 17.5942 90.0821 3.75415 73.0705 3.75415Z"
                            fill="#5F5F5F"
                          />
                          <path
                            d="M91.4191 39.6392L88.6447 37.5583C89.5806 35.7407 90.1201 33.6211 90.1201 31.358C90.1201 24.6429 85.3854 19.1797 79.5656 19.1797C73.7458 19.1797 69.0111 24.6429 69.0111 31.358C69.0111 38.0731 73.7458 43.5362 79.5656 43.5362C81.802 43.5362 83.8765 42.7272 85.5851 41.3529L88.4963 43.5364C89.2139 44.0745 90.2316 43.9292 90.7696 43.2117L91.7439 41.9127C92.282 41.1952 92.1364 40.1773 91.4191 39.6392ZM79.5656 38.6651C76.4319 38.6651 73.8824 35.3874 73.8824 31.3582C73.8824 27.329 76.4319 24.0512 79.5656 24.0512C82.6993 24.0512 85.2488 27.329 85.2488 31.3582C85.2488 32.5121 85.0389 33.6038 84.6669 34.5753L83.625 33.7938C82.9075 33.2557 81.8898 33.4011 81.3518 34.1186L80.3775 35.4176C79.8394 36.1351 79.985 37.1528 80.7023 37.6909L81.449 38.2509C80.8591 38.5182 80.2257 38.6651 79.5656 38.6651Z"
                            fill="#F3F1F1"
                          />
                          <path
                            d="M30.8516 3.75415C13.84 3.75415 0 17.5942 0 34.6058C0 51.6174 13.84 65.4574 30.8516 65.4574C47.8633 65.4574 61.7033 51.6174 61.7033 34.6058C61.7033 17.5942 47.8633 3.75415 30.8516 3.75415Z"
                            fill="#79C143"
                          />
                          <path
                            d="M35.7229 63.833C18.7113 63.833 4.87131 49.993 4.87131 32.9814C4.87131 20.2781 12.5895 9.3448 23.5822 4.62085C10.0672 7.89782 0 20.0968 0 34.6052C0 51.6168 13.84 65.4568 30.8516 65.4568C35.1599 65.4568 39.2642 64.5678 42.9924 62.9657C40.6602 63.531 38.2264 63.833 35.7229 63.833Z"
                            fill="#86AD32"
                          />
                          <path
                            d="M36.5359 24.0513V22.4275C36.5359 21.5308 35.8089 20.8037 34.9121 20.8037H21.1101C19.765 20.8037 18.6744 21.8943 18.6744 23.2394V42.7246C18.6744 43.6213 19.4015 44.3484 20.2982 44.3484H21.922C22.8187 44.3484 23.5457 43.6213 23.5457 42.7246V35.4176H31.6646C32.5613 35.4176 33.2884 34.6906 33.2884 33.7939V32.1701C33.2884 31.2734 32.5613 30.5463 31.6646 30.5463H23.5457V25.675H34.9121C35.8089 25.675 36.5359 24.948 36.5359 24.0513Z"
                            fill="#F3F1F1"
                          />
                          <path
                            d="M51.961 36.2295C34.9494 36.2295 21.1094 50.0695 21.1094 67.0811C21.1094 75.6118 24.6445 83.7475 30.8447 89.5722L27.6877 99.043C27.2491 100.358 28.6333 101.61 29.9001 101.035L41.0755 95.955C44.553 97.2678 48.2113 97.9328 51.961 97.9328C68.9726 97.9328 82.8126 84.0928 82.8126 67.0811C82.8126 50.0695 68.9726 36.2295 51.961 36.2295Z"
                            fill="#F68522"
                          />
                          <path
                            d="M32.5592 99.0428L35.7162 89.572C29.5158 83.7475 25.9807 75.6118 25.9807 67.0811C25.9807 50.8891 38.5192 37.5713 54.3967 36.3255C53.5929 36.2624 52.7808 36.2295 51.961 36.2295C34.9494 36.2295 21.1094 50.0695 21.1094 67.0811C21.1094 75.6118 24.6445 83.7475 30.8447 89.5722L27.6877 99.043C27.2491 100.358 28.6333 101.61 29.9001 101.035L32.5103 99.8482C32.4591 99.5933 32.4666 99.3201 32.5592 99.0428Z"
                            fill="#FFB357"
                          />
                          <path
                            d="M62.0653 77.7413L63.5175 77.0151C64.3197 76.614 64.6446 75.6387 64.2438 74.8366L60.8903 67.893H60.772L54.1382 54.6255C53.6625 53.6742 52.6141 53.1268 51.5323 53.3164C50.7549 53.4526 50.1028 53.9825 49.7498 54.6883L43.0288 67.893V68.1298L39.6753 74.8366C39.2743 75.6387 39.5994 76.614 40.4016 77.0151L41.854 77.7413C42.6562 78.1424 43.6314 77.8172 44.0325 77.0151L46.158 72.7641H57.7613L59.8868 77.0151C60.2879 77.8174 61.2631 78.1424 62.0653 77.7413ZM48.5937 67.893L51.9596 61.161L55.3256 67.893H48.5937Z"
                            fill="#F3F1F1"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2927_5727">
                            <rect
                              width="103.921"
                              height="103.921"
                              fill="white"
                              transform="translate(0 0.505615)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={18}
                      lg={18}
                      xl={18}
                      className="display-flex align-items-center"
                    >
                      <h2 className="border-box-left-title ">
                        Customer Interaction with the Platform + FAQ
                      </h2>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className="border-box-content"
                >
                  <label>
                    Hands-On session where participants will be given access to
                    the platform and explore the use-cases
                  </label>
                  <span className="border-box-recommended">
                    Recommended to bring your own laptop
                  </span>
                </Col>
              </Row>
            </div>
          </Col>
          <Col span={24} className="relative mt-5">
            <div className="gray-bg agenda-time">
              <label>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="21"
                  height="22"
                  viewBox="0 0 21 22"
                  fill="none"
                  className="mr-1"
                >
                  <g clip-path="url(#clip0_2927_5568)">
                    <path
                      d="M14.3883 12.7821L11.5791 10.6753V6.38349C11.5791 5.95196 11.2303 5.60315 10.7988 5.60315C10.3672 5.60315 10.0184 5.95196 10.0184 6.38349V11.0655C10.0184 11.3113 10.1339 11.543 10.3306 11.6897L13.4519 14.0307C13.5923 14.1361 13.7562 14.1868 13.9193 14.1868C14.1573 14.1868 14.3914 14.0799 14.5443 13.8739C14.8035 13.5297 14.7332 13.0404 14.3883 12.7821Z"
                      fill="white"
                    />
                    <path
                      d="M10.7973 0.928589C5.24213 0.928589 0.723267 5.44745 0.723267 11.0026C0.723267 16.5577 5.24213 21.0766 10.7973 21.0766C16.3524 21.0766 20.8713 16.5577 20.8713 11.0026C20.8713 5.44745 16.3524 0.928589 10.7973 0.928589ZM10.7973 19.5159C6.10361 19.5159 2.28391 15.6962 2.28391 11.0026C2.28391 6.30893 6.10361 2.48923 10.7973 2.48923C15.4917 2.48923 19.3106 6.30893 19.3106 11.0026C19.3106 15.6962 15.4909 19.5159 10.7973 19.5159Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_2927_5568">
                      <rect
                        width="20.148"
                        height="20.148"
                        fill="white"
                        transform="translate(0.723267 0.928589)"
                      />
                    </clipPath>
                  </defs>
                </svg>
                12:00 – 14:00
              </label>
            </div>
            <div className="border-box border-gray">
              <Row>
                <Col xs={24} sm={24} md={12} lg={12} xl={12}>
                  <Row>
                    <Col xs={24} sm={24} md={6} lg={6} xl={6}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="98"
                        height="99"
                        viewBox="0 0 98 99"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_2927_5756)">
                          <path
                            d="M51.2375 31.9172H17.0584L13.592 41.7994C13.3829 42.3953 13.2762 43.0224 13.2762 43.6538V55.1736L13.333 55.3641H30.8791C31.108 55.3641 31.3252 55.463 31.4755 55.6355C31.6259 55.8079 31.6939 56.0367 31.6626 56.2633L25.9438 97.6238L26.0753 98.0642H68.9621V46.7898C60.7685 45.3304 54.0482 39.5605 51.2375 31.9172Z"
                            fill="#FFE5C2"
                          />
                          <path
                            d="M84.1551 90.5367H81.0828L80.598 90.3462H41.9112L41.3914 90.5367H38.3191C37.2349 90.5367 36.3561 91.4155 36.3561 92.4997V98.0638H84.1551C85.2393 98.0638 86.1181 97.185 86.1181 96.1008V92.4997C86.1181 91.4157 85.2393 90.5367 84.1551 90.5367Z"
                            fill="#F6A96C"
                          />
                          <path
                            d="M23.5207 55.1737V43.6539C23.5207 43.0225 23.6274 42.3956 23.8364 41.7996L27.2359 32.1079H16.9915L13.592 41.7996C13.3829 42.3954 13.2762 43.0225 13.2762 43.6539V55.1737H23.5207Z"
                            fill="#FED2A4"
                          />
                          <path
                            d="M30.879 55.1731H2.07911C1.48366 55.1731 1.0254 55.6992 1.10696 56.2889L2.30662 64.9646L2.66199 65.1551H30.3106L30.6516 64.9646L31.8513 56.2889C31.9327 55.6992 31.4744 55.1731 30.879 55.1731Z"
                            fill="#8AC9FE"
                          />
                          <path
                            d="M49.8198 23.9228C49.8198 22.5604 49.9389 21.2253 50.1649 19.9265H15.2469C14.7048 19.9265 14.2654 20.3659 14.2654 20.908V31.1263C14.2654 31.6684 14.7048 32.1078 15.2469 32.1078H51.3105C50.3482 29.5608 49.8198 26.8025 49.8198 23.9228Z"
                            fill="#FED2A4"
                          />
                          <path
                            d="M28.6393 31.1263V20.908C28.6393 20.3659 29.0787 19.9265 29.6208 19.9265H15.2469C14.7048 19.9265 14.2654 20.3659 14.2654 20.908V31.1263C14.2654 31.6684 14.7048 32.1078 15.2469 32.1078H29.6208C29.0787 32.1078 28.6393 31.6684 28.6393 31.1263Z"
                            fill="#FFBD86"
                          />
                          <path
                            d="M39.5455 75.2922L38.4463 75.4828V80.0654C38.4463 81.6915 39.7645 83.0099 41.3908 83.0099L42.3888 83.2004H80.0961L81.0819 83.0099C82.708 83.0099 84.0264 81.6917 84.0264 80.0654V75.4828L82.9018 75.2922H39.5455Z"
                            fill="#00C27A"
                          />
                          <path
                            d="M60.5043 63.1201C60.0052 63.1201 59.5251 63.0386 59.0752 62.8901V68.1463H61.9334V62.8901C61.4835 63.0386 61.0033 63.1201 60.5043 63.1201Z"
                            fill="#A44F3E"
                          />
                          <path
                            d="M60.5068 63.3107C63.1376 63.3107 65.2704 61.1779 65.2704 58.5471C65.2704 55.9162 63.1376 53.7834 60.5068 53.7834C57.8759 53.7834 55.7432 55.9162 55.7432 58.5471C55.7432 61.1779 57.8759 63.3107 60.5068 63.3107Z"
                            fill="#FE6C55"
                          />
                          <path
                            d="M59.6451 58.5471C59.6451 56.6117 60.8 54.9471 62.4578 54.2015C61.8621 53.9336 61.2021 53.7834 60.5068 53.7834C57.8759 53.7834 55.7432 55.9162 55.7432 58.5471C55.7432 61.1779 57.8759 63.3107 60.5068 63.3107C61.2023 63.3107 61.8621 63.1603 62.4578 62.8926C60.8 62.147 59.6451 60.4824 59.6451 58.5471Z"
                            fill="#FD544D"
                          />
                          <path
                            d="M46.524 90.5369H38.3188C37.2346 90.5369 36.3558 91.4157 36.3558 92.4999V98.0639H44.5611V92.4999C44.5612 91.4158 45.44 90.5369 46.524 90.5369Z"
                            fill="#EA9B58"
                          />
                          <path
                            d="M46.6227 80.0653V75.4827H38.4463V80.0653C38.4463 81.6914 39.7645 83.0097 41.3908 83.0097H49.5672C47.9409 83.0097 46.6227 81.6914 46.6227 80.0653Z"
                            fill="#09A755"
                          />
                          <path
                            d="M81.0821 90.5364H41.3908C39.7647 90.5364 38.4463 89.2182 38.4463 87.5919V85.9538C38.4463 84.3277 39.7645 83.0093 41.3908 83.0093H81.0821C82.7082 83.0093 84.0266 84.3275 84.0266 85.9538V87.5919C84.0266 89.2182 82.7084 90.5364 81.0821 90.5364Z"
                            fill="#BB5D4C"
                          />
                          <path
                            d="M46.6227 87.5929V85.9547C46.6227 85.4797 46.7378 85.0325 46.9377 84.635L44.832 83.0103H41.3908C39.7647 83.0103 38.4463 84.3284 38.4463 85.9547V87.5929C38.4463 89.219 39.7645 90.5373 41.3908 90.5373H49.5672C47.9409 90.5371 46.6227 89.219 46.6227 87.5929Z"
                            fill="#A44F3E"
                          />
                          <path
                            d="M57.1058 91.5226L68.1381 83.0103H44.834L55.8663 91.5226C56.2314 91.8043 56.7405 91.8043 57.1058 91.5226Z"
                            fill="#FED402"
                          />
                          <path
                            d="M50.9432 83.0103H44.834L55.8663 91.5226C56.2314 91.8043 56.7407 91.8043 57.1058 91.5226L59.5408 89.6439L50.9432 83.0103Z"
                            fill="#FAC600"
                          />
                          <path
                            d="M84.1549 75.4834H38.3188C37.2346 75.4834 36.3558 74.6046 36.3558 73.5204V69.9193C36.3558 68.8351 37.2346 67.9563 38.3188 67.9563H84.1549C85.2391 67.9563 86.1178 68.8351 86.1178 69.9193V73.5204C86.1178 74.6046 85.2391 75.4834 84.1549 75.4834Z"
                            fill="#F6A96C"
                          />
                          <path
                            d="M44.5611 73.5204V69.9193C44.5611 68.8353 45.4399 67.9563 46.524 67.9563H38.3188C37.2346 67.9563 36.3558 68.8351 36.3558 69.9193V73.5204C36.3558 74.6046 37.2346 75.4834 38.3188 75.4834H46.524C45.4399 75.4834 44.5611 74.6046 44.5611 73.5204Z"
                            fill="#EA9B58"
                          />
                          <path
                            d="M2.30638 64.9658H10.5341L9.33442 56.2901C9.25287 55.7002 9.71113 55.1743 10.3066 55.1743H2.07887C1.48342 55.1743 1.02516 55.7004 1.10671 56.2901L2.30638 64.9658Z"
                            fill="#60B7FF"
                          />
                          <path
                            d="M2.30688 64.9656L6.64939 96.3705C6.78373 97.3416 7.61355 98.0647 8.5939 98.0647H26.0754L30.6521 64.9656H2.30688Z"
                            fill="#EAF6FF"
                          />
                          <path
                            d="M14.8771 96.3705L10.5346 64.9656H2.30688L6.64939 96.3705C6.78373 97.3416 7.61355 98.0647 8.5939 98.0647H16.8216C15.8414 98.0647 15.0114 97.3416 14.8771 96.3705Z"
                            fill="#D8ECFE"
                          />
                          <path
                            d="M73.0451 47.3403C85.9782 47.3403 96.4625 36.856 96.4625 23.923C96.4625 10.9899 85.9782 0.505615 73.0451 0.505615C60.1121 0.505615 49.6278 10.9899 49.6278 23.923C49.6278 36.856 60.1121 47.3403 73.0451 47.3403Z"
                            fill="#FE6C55"
                          />
                          <path
                            d="M53.9595 23.923C53.9595 15.5228 59.4145 8.37319 66.9679 5.82943C69.9357 3.39027 73.5114 1.6647 77.4301 0.919097C76.0093 0.648714 74.5436 0.505615 73.0451 0.505615C60.1327 0.505615 49.6278 11.0105 49.6278 23.923C49.6278 36.8354 60.1327 47.3403 73.0451 47.3403C74.5438 47.3403 76.0093 47.197 77.4303 46.9268C73.5114 46.1814 69.9358 44.4558 66.9681 42.0165C59.4146 39.4727 53.9595 32.3231 53.9595 23.923Z"
                            fill="#FD544D"
                          />
                          <path
                            d="M73.0433 41.5796C82.7948 41.5796 90.6999 33.6744 90.6999 23.923C90.6999 14.1715 82.7948 6.26636 73.0433 6.26636C63.2919 6.26636 55.3867 14.1715 55.3867 23.923C55.3867 33.6744 63.2919 41.5796 73.0433 41.5796Z"
                            fill="white"
                          />
                          <path
                            d="M74.3009 7.74891C73.8857 7.7169 73.4677 7.69556 73.0443 7.69556C64.0963 7.69556 56.8168 14.9753 56.8168 23.9231C56.8168 32.8709 64.0963 40.1506 73.0443 40.1506C73.4675 40.1506 73.8857 40.1293 74.3009 40.0973C68.0766 37.365 63.7172 31.1449 63.7172 23.9231C63.7172 16.7013 68.0766 10.4809 74.3009 7.74891Z"
                            fill="#F3F0F3"
                          />
                          <path
                            d="M73.0472 25.3518C72.9079 25.3518 72.7665 25.3315 72.6282 25.2888C72.0281 25.1047 71.6183 24.5504 71.6183 23.9226V15.3846C71.6183 14.5954 72.2579 13.9556 73.0474 13.9556C73.8368 13.9556 74.4765 14.5954 74.4765 15.3846V19.2651L75.1847 18.2198C75.6272 17.5664 76.5162 17.3957 77.1692 17.8381C77.8228 18.2807 77.9935 19.1694 77.5509 19.8228L74.2305 24.7242C73.9597 25.1239 73.5123 25.3518 73.0472 25.3518Z"
                            fill="#FD544D"
                          />
                          <path
                            d="M73.0433 7.50488C72.5615 7.50488 72.0857 7.53003 71.6143 7.571V10.0094C71.6143 10.7986 72.2539 11.4385 73.0433 11.4385C73.8328 11.4385 74.4724 10.7986 74.4724 10.0094V7.571C74.001 7.53023 73.525 7.50488 73.0433 7.50488Z"
                            fill="#A79BA7"
                          />
                          <path
                            d="M73.0433 36.4075C72.2539 36.4075 71.6143 37.0473 71.6143 37.8366V40.275C72.0857 40.3157 72.5615 40.3411 73.0433 40.3411C73.5252 40.3411 74.001 40.3159 74.4724 40.275V37.8366C74.4724 37.0473 73.8326 36.4075 73.0433 36.4075Z"
                            fill="#A79BA7"
                          />
                          <path
                            d="M60.5579 23.923C60.5579 23.1337 59.9182 22.4939 59.1288 22.4939H56.6904C56.6496 22.9653 56.6243 23.4411 56.6243 23.923C56.6243 24.4049 56.6494 24.8807 56.6904 25.3521H59.1288C59.9182 25.3521 60.5579 24.7124 60.5579 23.923Z"
                            fill="#A79BA7"
                          />
                          <path
                            d="M89.3945 22.4939H86.9561C86.1666 22.4939 85.527 23.1337 85.527 23.923C85.527 24.7122 86.1666 25.3521 86.9561 25.3521H89.3945C89.4352 24.8807 89.4606 24.4049 89.4606 23.923C89.4606 23.4411 89.4352 22.9653 89.3945 22.4939Z"
                            fill="#A79BA7"
                          />
                          <path
                            d="M73.0468 43.0088C62.523 43.0088 53.9611 34.4471 53.9611 23.9231C53.9611 13.3991 62.5228 4.8374 73.0468 4.8374C83.5707 4.8374 92.1324 13.3993 92.1324 23.9231C92.1324 34.4469 83.5705 43.0088 73.0468 43.0088ZM73.0468 7.69557C64.0988 7.69557 56.8192 14.9753 56.8192 23.9231C56.8192 32.8709 64.0988 40.1506 73.0468 40.1506C81.9947 40.1506 89.2743 32.8711 89.2743 23.9231C89.2743 14.9751 81.9947 7.69557 73.0468 7.69557Z"
                            fill="#FFA799"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_2927_5756">
                            <rect
                              width="97.5588"
                              height="97.5588"
                              fill="white"
                              transform="translate(0 0.505615)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                    </Col>
                    <Col
                      xs={24}
                      sm={24}
                      md={18}
                      lg={18}
                      xl={18}
                      className="display-flex align-items-center"
                    >
                      <h2 className="border-box-left-title ">
                        Lunch + Networking​​
                      </h2>
                    </Col>
                  </Row>
                </Col>
                <Col
                  xs={24}
                  sm={24}
                  md={12}
                  lg={12}
                  xl={12}
                  className="border-box-content"
                >
                  <label>
                    Connect with AWS and 1CloudHub GenAI Specialists,{" "}
                    <span className="content-bold">
                      learn how you can get started with a POC for your
                      use-case, supported by AWS Funding
                    </span>
                  </label>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Agenda;
