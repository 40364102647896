import {
  lineimg,
  viewdeImg,
  viewdeImg2,
  rocketImg,
  joinImg,
  illuImg,
  agendaImg,
  step1,
  step2,
  step3,
  step4,
  step5,
  spiral,
  Poinimg4,
  Poinimg3,
  Poinimg2,
  Poinimg1,
  eventImg2,
  eventImg3,
  eventImg5,
  qube,
} from "../assets/images";
import Form from "react-bootstrap/Form";
import Captcha from "./Captcha";
import React, { useState, useRef } from "react";

const RegDetail = (props) => {
  const [validated, setValidated] = useState(false);
  const nameRef = useRef(null);
  const organizationRef = useRef(null);
  const positionRef = useRef(null);
  const emailRef = useRef(null);
  const [successmessage, setSuccessMessage] = useState(false);
  const [loader, setLoader] = useState(false);
  const [userInput, setUserInput] = useState("");

  const [captchaerrorstatus, setCaptchsErrorStatus] = useState(false);
  const [captchacode, setCatpchaCode] = useState("");
  const [entercaptchacode, setEnterCaptchaCode] = useState("");
  // console.log(window.location.href);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === true) {
      if (captchacode === entercaptchacode) {
        setCaptchsErrorStatus(false);
        setLoader(true);
        const nameValue = nameRef.current.value;
        const organizationValue = organizationRef.current.value;
        const positionValue = positionRef.current.value;
        const emailValue = emailRef.current.value;
        var path = window.location.href;
        var eventtype = "registration";

        var myHeaders = new Headers();
        myHeaders.append("x-api-key", "da2-5s3fngqsi5a2xnlme7asxqubhy");
        myHeaders.append("Content-Type", "application/json");
        var graphql = JSON.stringify({
          query:
            'query MyQuery {\r\n  registration(email: "' +
            emailValue +
            '", name: "' +
            nameValue +
            '", organization: "' +
            organizationValue +
            '", position: "' +
            positionValue +
            '", event_type: "' +
            eventtype +
            '")\r\n}',
          variables: {},
        });
        var requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: graphql,
          redirect: "follow",
        };
        fetch(
          "https://xxp3ofdopjczxoxtnyjd47xn6y.appsync-api.ap-south-1.amazonaws.com/graphql",
          requestOptions
        )
          .then((response) => response.text())
          .then((result) => {
            nameRef.current.value = null;
            organizationRef.current.value = null;
            positionRef.current.value = null;
            emailRef.current.value = null;
            setUserInput("");
            setSuccessMessage(true);
            setValidated(false);
            setLoader(false);
            setTimeout(() => {
              setSuccessMessage(false);
            }, 3000);
          })
          .catch((error) => {
            console.log("error", error);
            setLoader(false);
          });
      } else {
        setCaptchsErrorStatus(true);
      }
    }
    setValidated(true);
  };

  return (
    <>
      <div className="reg_detail_sec">
        {/* <div className='head'>
          <h2>
            <span>VENUE</span> DETAILS
          </h2>
          <img src={lineimg} alt='Line_img' />
        </div>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12 col-lg-6 col-md-6'>
              <img src={viewdeImg} className='view_img' alt='view_img' />
            </div>
            <div className='col-sm-12 col-lg-6 col-md-6'>
              <img src={viewdeImg2} className='view_img' alt='view_img' />
            </div>
          </div>
        </div> */}
        <div className="reg_from_sec" ref={props.registrationFormRef}>
          <img src={illuImg} alt="illuImg" className="illu-img" />
          <div className="container">
            <div className="row">
              {successmessage && (
                <div class="alert alert-success alert-dismissible">
                  <strong>Success!</strong> Your details have been submitted.
                </div>
              )}
              <div className="col-sm-12 col-lg-5 col-md-6">
                <img className="rocket_img" src={rocketImg} alt="rocket_img" />
              </div>
              <div className="col-sm-12 col-lg-7 col-md-6">
                <img className="joinImg" src={joinImg} alt="joinImg" />
                <Form
                  noValidate
                  validated={validated}
                  onSubmit={handleSubmit}
                  className="reg_from"
                >
                  <div className="from-group">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Name*"
                      ref={nameRef}
                    />
                  </div>
                  <div className="from-group">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Organization*"
                      ref={organizationRef}
                    />
                  </div>
                  <div className="from-group">
                    <Form.Control
                      required
                      type="text"
                      placeholder="Position*"
                      ref={positionRef}
                    />
                  </div>

                  <div className="from-group">
                    <Form.Control
                      required
                      type="email"
                      placeholder="Email Address*"
                      ref={emailRef}
                    />
                  </div>
                  <div className="from-group">
                    <label></label>
                    <Captcha
                      setCaptchsErrorStatus={setCaptchsErrorStatus}
                      captchaerrorstatus={captchaerrorstatus}
                      setCatpchaCode={setCatpchaCode}
                      setEnterCaptchaCode={setEnterCaptchaCode}
                      userInput={userInput}
                      setUserInput={setUserInput}
                    />
                  </div>
                  <button disabled={loader} className="btn join_btn">
                    {loader && (
                      <span
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                    )}
                    JOIN US
                  </button>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="agenda_total_sec">
        <img src={illuImg} alt="" className="elli-img" />
        <img src={agendaImg} alt="agendaImg" className="agenda-img" />
        <img src={qube} alt="qube" className="qube_img" />
        <div className="container">
          <div className="row">
            <div className="totalstep_sec">
              <div className="img_content">
                <img src={step1} alt="" className="step-img" />
                <div>
                  <h3>Leadership Key Note</h3>
                </div>
              </div>
              <img src={Poinimg2} alt="spiral" className="spi-img" />
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="totalstep_sec">
              <div className="img_content">
                <img src={step2} alt="" className="step-img" />
                <div>
                  <h3>
                    Streamline and Simplify <br />
                    Your Modernization Journey <br />
                    with AWS
                  </h3>
                </div>
              </div>
              <img src={eventImg2} alt="spiral" className="spi-img" />
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="totalstep_sec">
              <div className="img_content">
                <img src={step3} alt="" className="step-img" />
                <div>
                  <h3>
                    Supercharge Your Business <br />
                    Outcomes with Generative AI
                  </h3>
                </div>
              </div>
              <img src={eventImg3} alt="spiral" className="spi-img" />
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="totalstep_sec">
              <div className="img_content">
                <img src={step4} alt="" className="step-img" />
                <div>
                  <h3>
                    Shaping an AI-First Culture <br />
                    with AIVolveX Gen AI Platform
                  </h3>
                </div>
              </div>
              <img src={spiral} alt="spiral" className="spi-img" />
            </div>
            <hr />
          </div>
          <div className="row">
            <div className="totalstep_sec">
              <div className="img_content">
                <img src={step5} alt="" className="step-img" />
                <div>
                  <h3>Networking & Dinner</h3>
                </div>
              </div>
              <img src={eventImg5} alt="spiral" className="spi-img" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RegDetail;
