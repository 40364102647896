import { Col, Row } from "antd";
import "./website.css";
import {
  frameleft,
  frameright,
  ochLogo,
  line,
  awslogo,
  frame,
  lionbanner,
  eventdetails,
  contact,
  postleftimg,
  postrightimg,
  whyshouldcontent,
  keytopicscontent,
} from "../../assets/singapore";
import { Button, Form, Input, message } from "antd";
import FourBox from "./FourBox";
import Agenda from "./Agenda";
import { useState } from "react";

const Website = () => {
  const [form] = Form.useForm();
  const [loader, setLoader] = useState(false);

  const handleRegisterButtonClick = () => {
    const registerFormElement = document.getElementById("registerForm");
    if (registerFormElement) {
      registerFormElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onFinish = (values) => {
    setLoader(true);
    const formdata = new FormData();
    formdata.append("Name", values.name);
    formdata.append("Organization", values.organization);
    formdata.append("Position", values.position);
    formdata.append("Email", values.email);
    formdata.append("Phone_number", values.phone);
    formdata.append("Message", values.message);

    const requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(
      "https://script.google.com/macros/s/AKfycbzVazwMrkK5ZtDfUptR3m0h_BYSTyM9_OIE_sxDap2tBoQfWFyK10Uuf5TB36-_qp1-5Q/exec",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        mailTrigger(values);
      })
      .catch((error) => console.error(error));
  };

  const mailTrigger = (values) => {
    // console.log(values);
    const name = values.name;
    const email = values.email;
    const job_title = values.position;
    const organization = values.organization;
    const phone_number = values.phone;
    const formmessage = values.message;

    const myHeaders = new Headers();
    myHeaders.append("x-api-key", "da2-vhjjnmmbinepdeo2nc36sroq6i");
    myHeaders.append("Content-Type", "application/json");

    const graphql = JSON.stringify({
      query:
        'query MyQuery {\r\n  website_email_trigger(email_id: "' +
        email +
        '", job_title: "' +
        job_title +
        '", members_name: "' +
        name +
        '", organization: "' +
        organization +
        '", phone_number: "' +
        phone_number +
        '", message: "' +
        formmessage +
        '", event_type: "singapore_event")\r\n}\r\n',
      variables: {},
    });
    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: graphql,
      redirect: "follow",
    };

    fetch(
      "https://caqt4znsszh73kqzimjn22eexu.appsync-api.ap-south-1.amazonaws.com/graphql",
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        form.resetFields();
        message.success(
          "Thank you for sharing your details! We'll be in touch soon"
        );
        setLoader(false);
      })
      .catch((error) => console.error(error));
  };
  return (
    <>
      <section>
        <Row>
          <Col span={24}>
            <div className="header-footer-border"></div>
          </Col>
        </Row>
      </section>
      <section className="relative mt-5">
        <img
          src={frameleft}
          alt="img"
          className="banner-left-frame absolute frame-w100"
        />
        <img
          src={frameright}
          alt="img"
          className="banner-right-frame absolute frame-w100"
        />
        <Row className="container">
          <Col span={24}>
            <div className="text-center">
              <img src={ochLogo} alt="logo" />
            </div>
            <div className="text-center mt-4">
              <h1 className="banner-text">
                <span className="banner-text-gradient">
                  Generative AI Solutions
                </span>{" "}
                for Financial Services
              </h1>
            </div>
            <div className="text-center mt-5 mb-5">
              <img src={line} alt="img" className="frame-w100" />
            </div>
            <div className="text-center frame-w100">
              <div className="display-flex align-items-center justify-content-center">
                <div>
                  <img src={ochLogo} alt="logo" />
                </div>
                <div className="v-line"></div>
                <div>
                  <img src={awslogo} alt="logo" />
                </div>
              </div>
            </div>
            <div className="text-center mt-5">
              <div>
                <button className="btn-web" onClick={handleRegisterButtonClick}>
                  Register Now{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 9L14 12L11 15M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </Col>
        </Row>
      </section>
      <section className="mt-5">
        <Row className="container">
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="text-center">
            <img src={frame} alt="img" className="w100" />
          </Col>
          <Col
            xs={24}
            sm={24}
            md={12}
            lg={12}
            xl={12}
            className="display-flex align-items-center"
          >
            <p className="about-content">
              Join us for an exclusive in-person event to learn how AWS
              certified partner 1CloudHub (in partnership with AWS) is powering
              next-generation Generative AI solutions for financial institutions
              through a newly built software as a service (SaaS) platform
              AIVolveX. Financial Services firms gain competitive advantages
              through improved employee productivity, better insights, quicker
              decisions, and enhanced customer experiences, while maintaining
              top-notch security and governance.
              <br></br>
            </p>
          </Col>
        </Row>
      </section>
      <section className="container mt-5">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} className="text-center">
            <h1>
              <span className="registerf-title">What sets AIVolveX apart?</span>
            </h1>
            <p className="about-content">
              Built using Amazon Bedrock, AIVolveX is a plug-and-play solution,
              accelerating time to adoption in the fast-evolving world of
              Generative AI. In this session, explore ready-made financial
              service use cases such as document search & analysis, Chatbots
              deployment, data & analytics and much more. You can even try the
              platform and AWS bundles to get started with Generative AI at no
              upfront cost.
            </p>
          </Col>
        </Row>
      </section>
      <section className="container mt-5">
        <Row gutter={[16, 16]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="relative">
              <div className="line-banner-text-center">
                <span>Registration starts at 9:30AM</span>
              </div>
              <img src={eventdetails} alt="img" className="w100" />
            </div>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <div className="relative">
              <div className="absolute lion-banner-start1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="61"
                  height="61"
                  viewBox="0 0 61 61"
                  fill="none"
                >
                  <path
                    d="M0.0131829 30.3219C11.907 31.3972 18.7408 32.3883 22.9373 35.671C27.8693 39.5153 29.0401 46.5593 30.3436 60.6477C31.678 46.0395 32.8859 39.0377 38.2904 35.2754C42.4843 32.3348 49.2739 31.3811 60.6479 30.3389C48.7973 29.2636 41.9203 28.2726 37.767 25.033C32.7917 21.1456 31.621 14.1448 30.3174 0.0130818C29.1121 13.0222 28.0339 19.9807 24.0135 24.0017C19.9931 28.0227 12.9873 29.1494 0.0131829 30.3219Z"
                    fill="#FE6D00"
                  />
                </svg>
              </div>
              <div className="absolute lion-banner-start2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="23"
                  viewBox="0 0 23 23"
                  fill="none"
                >
                  <path
                    d="M0 11.4918C4.51141 11.9016 7.10343 12.2787 8.69472 13.5246C10.5649 14.9836 11.0078 17.6557 11.5 23C12.0086 17.459 12.4679 14.8033 14.5185 13.377C16.1098 12.2623 18.6854 11.9016 23 11.5082C18.505 11.0984 15.8966 10.7213 14.3217 9.4918C12.4351 8.01639 11.9922 5.36066 11.5 0C11.0407 4.93443 10.6305 7.57377 9.10485 9.09836C7.57917 10.623 4.92154 11.0492 0 11.4918Z"
                    fill="#FE6D00"
                  />
                </svg>
              </div>
              <img src={lionbanner} alt="img" className="w100" />
            </div>
          </Col>
        </Row>
      </section>
      <section className="container mt-5" id="registerForm">
        <Row>
          <Col span={24} className="text-center">
            {/* <div className="relative">
              <div className="join-us-poisition">
                <img src={line} alt="img" className="line-bg" />
              </div>
            </div> */}

            <div class="eight">
              <h1 className="join-us-now">Join Us Now</h1>
            </div>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img src={contact} alt="img" className="w100" />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <h1 className="registerf-title">Registration</h1>
            <Form
              name="basic"
              wrapperCol={{
                span: 24,
              }}
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              autoComplete="off"
              form={form}
            >
              <Form.Item
                label=""
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please input your Name!",
                  },
                ]}
              >
                <Input
                  className="custom-placeholder-color"
                  placeholder="Name"
                />
              </Form.Item>
              <Form.Item
                label=""
                name="organization"
                rules={[
                  {
                    required: true,
                    message: "Please input your Organization!",
                  },
                ]}
              >
                <Input
                  className="custom-placeholder-color"
                  placeholder="Organization"
                />
              </Form.Item>
              <Form.Item
                label=""
                name="position"
                rules={[
                  {
                    required: true,
                    message: "Please input your Position!",
                  },
                ]}
              >
                <Input
                  className="custom-placeholder-color"
                  placeholder="Position"
                />
              </Form.Item>
              <Form.Item
                label=""
                name="email"
                rules={[
                  {
                    type: "email",
                    required: true,
                    message: "Please input your Email Address",
                  },
                ]}
              >
                <Input
                  className="custom-placeholder-color"
                  placeholder="Email Address"
                />
              </Form.Item>
              <Form.Item
                label=""
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Please input your Phone Number!",
                  },
                ]}
              >
                <Input
                  className="custom-placeholder-color"
                  placeholder="Phone Number"
                />
              </Form.Item>

              <Form.Item
                label=""
                name="message"
                rules={[
                  {
                    required: true,
                    message: "Please input your Message!",
                  },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  className="custom-placeholder-color"
                  placeholder="Message"
                />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  span: 24,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  className="btn-web"
                  loading={loader}
                >
                  Register Now{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11 9L14 12L11 15M21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12Z"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </Button>
              </Form.Item>
            </Form>
          </Col>
        </Row>
      </section>
      <FourBox />
      <section className="mt-5 container">
        <Row gutter={[16, 16]} className="mt-5">
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="text-center">
            <h1 className="join-us-now text-uppercase">Who should attend?</h1>
            <img src={whyshouldcontent} alt="img" className="w100" />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="text-center">
            <h1 className="join-us-now text-uppercase">Key topics</h1>
            <img src={keytopicscontent} alt="img" className="w100" />
          </Col>
        </Row>
      </section>
      <Agenda />
      <section className="mt-5 container">
        <Row>
          <Col span={24} className="text-center">
            <div class="eight">
              <h1 className="join-us-now">Featured Use Cases</h1>
            </div>
          </Col>
        </Row>
        <Row gutter={[16, 16]} className="mt-4">
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img src={postleftimg} alt="img" className="w100" />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12}>
            <img src={postrightimg} alt="img" className="w100" />
          </Col>
        </Row>
        <Row>
          <Col span={24} className="text-center">
            <h1 className="post-footer-content">
              Looking forward to meeting you there.
            </h1>
          </Col>
        </Row>
      </section>
      <section>
        <Row>
          <Col span={24}>
            <div className="header-footer-border"></div>
          </Col>
        </Row>
      </section>
      <section className="mt-5">
        <Row>
          <Col span={24}>
            <div className="text-center">
              <img src={ochLogo} alt="logo" />
            </div>
          </Col>
          <Col span={24} className="mt-3">
            <div className="text-center">
              <label className="footer-content">
                © 1 Cloud Hub Pte Ltd. {new Date().getFullYear()}. All Rights
                Reserved.
              </label>
            </div>
          </Col>
        </Row>
      </section>
    </>
  );
};

export default Website;
