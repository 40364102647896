import React, { useRef } from "react";
import Banner from "../components/Banner";
import RegDetail from "../components/RegDetail";
import Diginitaries from "../components/Diginitaries";
import Footer from "../components/Footer";

const Home = () => {
  const registrationFormRef = useRef();
  const scrollToRegistrationForm = () => {
    // Step 5: Use the ref's current property and scrollIntoView
    registrationFormRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Banner scrollToRegistrationForm={scrollToRegistrationForm} />
      <RegDetail registrationFormRef={registrationFormRef} />
      {/* <Diginitaries /> */}
      <Footer />
    </>
  );
};

export default Home;
