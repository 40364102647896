import React, { useState, useEffect } from "react";

const CustomCaptchaWithImage = (props) => {
  const [captchaText, setCaptchaText] = useState("");

  const [captchaMatch, setCaptchaMatch] = useState(true);

  useEffect(() => {
    // Generate a new CAPTCHA text when the component mounts
    refreshCaptcha();
  }, []);

  const generateRandomString = (length) => {
    const charset =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset[randomIndex];
    }
    return result;
  };

  const refreshCaptcha = () => {
    // Generate a new CAPTCHA text and reset user input
    const newCaptchaText = generateRandomString(6);
    props.setCatpchaCode(newCaptchaText);
    setCaptchaText(newCaptchaText);
    props.setUserInput("");
  };

  const handleUserInput = (e) => {
    props.setUserInput(e.target.value);
    props.setEnterCaptchaCode(e.target.value);
  };

  const verifyCaptcha = () => {
    const isMatch = props.userInput === captchaText;
    setCaptchaMatch(isMatch);
  };

  useEffect(() => {
    // Draw the CAPTCHA text on the canvas with white color
    const canvas = document.getElementById("captchaCanvas");
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.font = "30px Arial";
    context.fillStyle = "white"; // Set text color to white
    context.fillText(captchaText, 10, 30);
  }, [captchaText]);

  return (
    <div>
      <div>
        <canvas id="captchaCanvas" width="200" height="60"></canvas>
        <input
          type="text"
          value={props.userInput}
          onChange={handleUserInput}
          placeholder="Enter the Captcha code above"
        />
        {/* <button onClick={verifyCaptcha}>Submit</button> */}
        {/* <button onClick={refreshCaptcha} className="btn join_btn">
          Refresh
        </button> */}
        {props.captchaerrorstatus && (
          <p style={{ color: "red" }}>Invalid captcha, please try again.</p>
        )}
      </div>
    </div>
  );
};

export default CustomCaptchaWithImage;
